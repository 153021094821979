<template>

    <Section :options="sectionOptions" class="team-group">

        <div v-if="showTitle" :class="titleCssClass">
            <h2 v-if="labels.h2 && labels.h2 != ''">{{ $cms.utils.getComputedLabel(labels.h2) }}</h2>
            <h3 v-if="labels.h3 && labels.h3 != ''">{{ $cms.utils.getComputedLabel(labels.h3) }}</h3>
        </div>
    
        <div class="row col-mb-50 mb-0">

            <div :class="memberCssClass" v-for="(member, index) in members" :key="index + '-member'">

                <div class="team team-list grid-inner g-0">
                    <div class="team-image">
                        <img v-if="member.picture" :src="member.picture" :alt="member.name">
                        <img v-else src="@public/img/authentication/profile-blank.jpg" :alt="member.name">
                        <img v-if="member.hoverPicture" :src="member.hoverPicture" :alt="member.name">
                    </div>
                    <div class="team-desc">
                        <div class="team-title">
                            <h4>{{member.name}}</h4>
                            <span v-if="member.title">{{member.title}}</span>
                        </div>
                        <div class="contacts">
                            <span v-if="member.phone"><strong>{{ $cms.utils.getComputedLabel(labels.phone) }} </strong> {{member.phone}}<br/></span>
                            <span v-if="member.email"><strong>{{ $cms.utils.getComputedLabel(labels.email) }} </strong> {{member.email}}<br/></span>
                        </div>
                        <LocalizedContent v-if="member.text" class="team-content" tag="div" :localizedContent="member.text"></LocalizedContent>
                        <SocialLinks v-if="member.socialMedias" :options="{iconClass:'si-rounded si-small', socialMedias: member.socialMedias}" />
                    </div>
                </div>

            </div>

        </div>
    
    </Section>

</template>

<style>

/* We set the size of the picture */
.team-list .team-image {
    height:100px;
    width: 100px;
    padding-top:5px;
    padding-bottom:5px;
    margin-right: 30px;
    flex: 0 0 auto;
}

/* We set the size of the picture */
.team-list .team-image > a {
    height:100%;
}

/* We set the size of the picture */
.team-list .team-image img {
    width: auto;
    height: 100%;
    margin-left:auto;
    margin-right:auto;
}

/* We set shadow and margin for product list item */
.team-list {
    margin: 0 0 40px 0;
    padding: 0;
    width: 100%;
}

/* We ensure that picture is verticaly aligned with content */
.team-list.grid-inner {
    display:flex;
    align-items: center;
}

/* We ensure that picture and content wrap for mobile */
/* picture takes full width and with small margin bottom */
@media (max-width: 767.98px) {
    .team-list .team-image {
        width: 100%;
        margin-bottom: 20px;
    }
    .team-list.grid-inner {
      flex-wrap: wrap;
    } 
}

/* We display the content next to the picture */
.team-list .team-desc {
    padding-top:5px;
    padding-right:10px;
    padding-bottom: 0px;

    flex: 0 1 auto;
    align-self: stretch;

    display: flex;
    flex-direction: column;
}

@media (max-width: 767.98px) {
    .team-list .team-desc {
      padding-left:10px;
    } 
}

.team-list .team-desc .team-title {
    margin-bottom: 10px;
}

.team-group .team-image > div {
    position:relative;
    height:100%;
    overflow:hidden;
}

.team-group .team-image > div > img {
    transition: opacity 0.5s;
    height:100%;
    width:auto;
    min-width:100%;
    max-width:none;
    margin-left:50%;
    transform: translate(-50%, 0);
}

.team-group .team-image > div > img:nth-child(2) {
    position:absolute;
    top:0px;
    left:0px;
}

.team-group .team-image:hover > div > img:nth-child(2) {
    opacity: 0;
}
</style>

<script lang="ts">
import { defineComponent, PropType, watch, computed, onMounted, onBeforeUnmount, ref } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import Section, { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import { CmsLabel, CmsText,CmsContent, CmsBoolean, CmsEnum } from '@fwk-client/modules/cms/types';
import LocalizedContent from '@fwk-client/components/panels/LocalizedContent.vue';
import SocialLinks from '../social/SocialLinks.vue';
import { Member } from './types';

/** @cmsOptions */
export interface GroupOptions {
    sectionOptions?:SectionOptions,
    /** @cmsType CmsEnum */
    titleStyle?:CmsEnum<"heading-block"|"fancy-title">,
    /** @cmsType CmsInterface */
    members:Member[]
}

/** @cmsLabels */
export interface GroupLabels {
  /** @cmsType CmsLabel */
  h2?:CmsLabel;
  /** @cmsType CmsLabel */
  h3?:CmsLabel;
  /** @cmsType CmsLabel */
  phone?:CmsLabel;
  /** @cmsType CmsLabel */
  email?:CmsLabel;
}

/** @cmsSlots */
export interface GroupSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<GroupOptions>,
        labels: {
          type: Object as PropType<GroupLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<GroupSlots>
    },
    components: {
        Section,
        LocalizedContent,
        SocialLinks
    },
    setup(props, context) {

        const app = getApp();
        const $router = useRouter();
        const $store = useStore();

        const showTitle = props.labels.h2 || props.labels.h3;
        
        
        const computedOptions:GroupOptions = {
            sectionOptions : {
                
            },
            titleStyle : "heading-block",
            members:[],
            ...props.options
        };

        const titleCssClass = computedOptions.titleStyle == "heading-block" ? "heading-block center" : "fancy-title title-border title-center";

        const computedLabels:GroupLabels = {
            phone : {
                fr : "Téléphone:"
            },
            email : {
                fr : "E-Mail:"
            },
            ...props.labels
        }

        const memberCssClass = computed(() => {
            if(computedOptions.members.length == 1) {
                return "col-auto ms-auto me-auto"
            }
            return "col-lg-6"
        })

        return {
            sectionOptions:computedOptions.sectionOptions,
            labels:computedLabels,
            showTitle:showTitle,
            titleCssClass:titleCssClass,
            members:computedOptions.members,
            memberCssClass:memberCssClass
        }

    }
})
</script>