<template>
    <div class="social-medias clearfix">
        <a v-if="computedOptions.socialMedias.facebook" :href="'https://www.facebook.com/'+computedOptions.socialMedias.facebook" target="_blank" :class="'social-icon si-facebook' + (computedOptions.iconClass ? ' ' + computedOptions.iconClass : '')">
            <i class="icon-facebook"></i>
            <i class="icon-facebook"></i>
        </a>
        <a v-if="computedOptions.socialMedias.instagram" :href="'https://www.instagram.com/'+computedOptions.socialMedias.instagram" target="_blank" :class="'social-icon si-instagram' + (computedOptions.iconClass ? ' ' + computedOptions.iconClass : '')">
            <i class="icon-instagram"></i>
            <i class="icon-instagram"></i>
        </a>
        <a v-if="computedOptions.socialMedias.linkedin" :href="'https://www.linkedin.com/in/'+computedOptions.socialMedias.linkedin" target="_blank" :class="'social-icon si-linkedin' + (computedOptions.iconClass ? ' ' + computedOptions.iconClass : '')">
            <i class="icon-linkedin"></i>
            <i class="icon-linkedin"></i>
        </a>
    </div>
</template>

<style>

</style>

<script lang="ts">
import { defineComponent, PropType, watch, computed, onMounted, onBeforeUnmount, ref } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore, useCmsModule } from '@fwk-client/utils/vue-3-migration';
import Section, { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import { CmsPicture, CmsEnum, CmsLabel, CmsContent, CmsText } from '@fwk-client/modules/cms/types';
import { SocialMedias } from './types';

/** @cmsOptions */
export interface SocialLinksOptions {
    /** @cmsType CmsText */
    iconClass?:string;
    /** @cmsType CmsInterface */
    socialMedias?:SocialMedias;
}

/** @cmsLabels */
export interface SocialLinksLabels {
    
}

/** @cmsSlots */
export interface SocialLinksSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<SocialLinksOptions>,
        labels: {
          type: Object as PropType<SocialLinksLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<SocialLinksSlots>
    },
    components: {
        Section
    },
    setup(props, context) {

        const app = getApp();
        const $router = useRouter();
        const $store = useStore();
        const cms = useCmsModule();

        
        const computedOptions:SocialLinksOptions = {
            iconClass: "si-small si-text-color",
            ...props.options
        };

        return {
            computedOptions,
            labels:props.labels
        }

    }
})
</script>